import React from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";
import { styled } from "twin.macro";
import "twin.macro";
// const StaticImageStyled = styled(StaticImage)`
//   height: 684px;
// `;

const StyledSlidder = styled(Slider)`
  /* Arrows */

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    z-index: 20;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }

  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
    background-color: "#e38a17";
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    line-height: 1;
    opacity: 0.9;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: 5px;
    /* background-color: red; */
    /* z-index: 20; */
  }

  [dir="rtl"] .slick-prev {
    right: -5px;
    left: auto;
    z-index: 20;
    /* background-color: red; */
    /* background-color: "#e38a17"; */
  }

  .slick-prev:before {
    content: "<";
    padding: 10px 15px 12px;
    background-color: #e38a17;
  }

  [dir="rtl"] .slick-prev:before {
    content: ">";
    padding: 10px;
    /* background-color: red; */
  }

  .slick-next {
    right: 25px;
  }

  [dir="rtl"] .slick-next {
    right: auto;
    left: -5px;
  }

  .slick-next:before {
    content: ">";
    padding: 10px 15px 12px;
    background-color: #e38a17;
  }

  [dir="rtl"] .slick-next:before {
    content: "<";
  }

  @media screen and (max-width: 768px) {
    .slick-next:before {
      content: ">";
      padding: 10px 15px 12px;
      /* background-color: #e3175e; */
    }
  }
`;
export default function Projekte() {
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    className: "",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,

          // initialSlide: 2
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  return (
    <div tw="px-2 py-16 bg-pribg sm:px-6 md:px-12" id="projekte">
      <span tw="flex items-center mb-20 w-full md:mb-28">
        {/* <hr tw="flex-none items-center self-center w-full h-1 bg-primary" /> */}
        <div
          tw="hidden w-full sm:block"
          className=""
          // style={{ borderColor: "#902b4b", border: "5px solid" }}
        >
          <hr tw="items-center self-center w-full h-1 bg-primary" />
        </div>

        <div
          className="flex-initial"
          tw="mx-4 w-full text-center text-4xl font-semibold sm:whitespace-nowrap"
        >
          Unsere Projekte
        </div>
        {/* <hr tw="items-center self-center w-full h-1 bg-primary" /> */}
        <div
          tw="hidden w-full sm:block"
          className=""
          // style={{ borderColor: "#902b4b", border: "5px solid" }}
        >
          <hr tw="items-center self-center w-full h-1 bg-primary" />
        </div>
      </span>

      <div tw="">
        <p tw="my-8 text-center text-xl lg:my-12">
          Eine Auswahl verschiedener Küchen, die wir für unsere Kunden
          verwirklichen durften.
        </p>
        <div className="">
          <StyledSlidder {...settings} tw="w-full">
            {/* 1 */}
            {/* <ul tw="mx-auto grid grid-cols-2 col-gap-4 row-gap-8 sm:grid-cols-4 md:col-gap-6 lg:max-w-5xl lg:col-gap-8 lg:row-gap-12 xl:grid-cols-6 ">
              <li>
                <div tw="space-y-4 px-4"> */}
            <StaticImage
              tw="w-full h-80 md:w-150 md:h-124"
              src="../images/2020-12-09-09.59.03.jpg"
              alt=""
            />

            {/* </div>
              </li>
            </ul> */}
            <StaticImage
              src="../images/2020-12-09-09.59.33.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/2020-12-09-09.59.03.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/Retusche-MD.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/MG_0259.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/MG_0256.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/MG_0023.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/MG_0004.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/MG_0014.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/MG_0033.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/MG_0003.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/MG_0034.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/MG_0058.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/MG_0033.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/20180120_104651.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/DSC09828.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
            <StaticImage
              src="../images/DSC09825.jpg"
              alt="Team"
              tw="w-full h-80 md:w-150 md:h-124"
              //   className="h-80 md:h-124 w-full md:w-150 "
            />
          </StyledSlidder>
        </div>
      </div>
    </div>
  );
}
