import React from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";
import { styled } from "twin.macro";
import "twin.macro";
// const StaticImageStyled = styled(StaticImage)`
//   height: 684px;
// `;

const StyledSlidder = styled(Slider)`
  /* Arrows */

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    z-index: 20;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }

  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
    background-color: "#e38a17";
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 18px;
    font-weight: 900;
    line-height: 1;
    opacity: 0.9;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: 5px;
    /* background-color: red; */
    /* z-index: 20; */
  }

  [dir="rtl"] .slick-prev {
    right: -25px;
    left: auto;
    z-index: 20;
    /* background-color: red; */
    /* background-color: "#e38a17"; */
  }

  .slick-prev:before {
    content: "<";
    padding: 10px 15px 12px;

    background-color: #e38a17;
  }

  [dir="rtl"] .slick-prev:before {
    content: ">";
    padding: 10px;
    /* background-color: red; */
  }

  .slick-next {
    right: 25px;
  }

  [dir="rtl"] .slick-next {
    right: auto;
    left: -25px;
  }

  .slick-next:before {
    content: ">";
    padding: 10px 15px 12px;

    background-color: #e38a17;
  }

  [dir="rtl"] .slick-next:before {
    content: "<";
  }
`;
export default function Kuchenstudio() {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div tw="px-2 py-16 bg-pribg sm:px-6 md:px-12" id="kuchenstudio">
      <span tw="flex items-center mb-20 w-full lg:mb-28">
        {/* <hr tw="flex-none items-center self-center w-full h-1 bg-primary" /> */}
        <div
          tw="hidden w-full md:block"
          className=""
          // style={{ borderColor: "#902b4b", border: "5px solid" }}
        >
          <hr tw="items-center self-center w-full h-1 bg-primary" />
        </div>

        <p
          className="flex-initial"
          tw="mx-4 w-full text-center text-4xl font-semibold md:whitespace-nowrap"
        >
          Ihr charmantes Küchenstudio in Bielefeld
        </p>
        {/* <hr tw="items-center self-center w-full h-1 bg-primary" /> */}
        <div
          tw="hidden w-full md:block"
          className=""
          // style={{ borderColor: "#902b4b", border: "5px solid" }}
        >
          <hr tw="items-center self-center w-full h-1 bg-primary" />
        </div>
      </span>
      <div className="w-full" tw="lg:flex lg:gap-10">
        <div tw="mb-8 lg:mb-0 lg:w-1/3">
          <div>
            <div
              className="prose lg:prose-xl px-8 text-black"
              tw="py-2 text-xl font-medium"
            >
              <p tw="my-4 text-2xl font-bold">
                Ihre neue Küche finden Sie bei uns !
              </p>
              <p tw="my-4">
                <span>
                  Egal, ob Sie sich eher eine verspielte Landhausküche, einen
                  modernen Klassiker oder eine topaktuelle Trendplanung
                  wünschen:
                </span>
                <br />
                <span>
                  Bei uns bekommen Sie genau die Küche, die zu Ihnen passt.
                </span>
              </p>
              <p tw="my-4">
              Bereits seit 2004 kümmern wir uns um die Wünsche unserer Kunden, rund um das Thema Küche.
              </p>
              <p tw="my-4">
                Erleben Sie in angenehmer Atmosphäre den Service und die
                Leistungsfähigkeit eines modernen Küchenstudios, von der ersten
                Beratung, über die Planung, bis hin zur Montage Ihrer neuen
                Traumküche.
              </p>
              <p tw="my-4">Wir freuen uns auf Ihren Besuch !</p>
              <p tw="my-4 font-bold">
                Martina Arians-Burmann und Dirk Brinkmann – Ihre Küchenexperten
                in Bielefeld
              </p>
            </div>
          </div>
        </div>
        <div className="md:col-end-4 md:col-start-2" tw="w-full lg:w-2/3">
          <StyledSlidder {...settings} tw="w-full">
            <StaticImage
              tw="w-52 h-80 md:w-120 md:h-124"
              src="../images/IMG_9326.jpg"
              alt=""
            />
            <StaticImage
              tw="w-52 h-80 md:w-120 md:h-124"
              src="../images/IMG_9329.jpg"
              alt=""
            />
            <StaticImage
              tw="w-52 h-80 md:w-120 md:h-124"
              src="../images/IMG_9328.jpg"
              alt=""
            />
            <StaticImage
              tw="w-52 h-80 md:w-120 md:h-124"
              src="../images/IMG_9325.jpg"
              alt=""
            />
            <StaticImage
              tw="w-52 h-80 md:w-120 md:h-124"
              src="../images/IMG_9324.jpg"
              alt=""
            />
            <StaticImage
              tw="w-52 h-80 md:w-120 md:h-124"
              src="../images/IMG_9321.jpg"
              alt=""
            />
            <StaticImage
              tw="w-52 h-80 md:w-120 md:h-124"
              src="../images/IMG_9320.jpg"
              alt=""
            />
            <StaticImage
              tw="w-52 h-80 md:w-120 md:h-124"
              src="../images/IMG_9318.jpg"
              alt=""
            />
            <StaticImage
              tw="w-52 h-80 md:w-120 md:h-124"
              src="../images/IMG_9317.jpg"
              alt=""
            />
          </StyledSlidder>
          {/* <p tw="text-3xl font-extrabold">Fuck It </p> */}
        </div>
      </div>
    </div>
  );
}
