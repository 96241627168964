import React from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";
import { styled } from "twin.macro";

// import ReactStars from "react-rating-stars-component";
const StyledSlidder = styled(Slider)`
  /* Arrows */

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    z-index: 20;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }

  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
    background-color: "#e38a17";
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 14px;
    font-weight: 900;
    line-height: 1;
    opacity: 0.9;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: 30px;
    /* background-color: red; */
    /* z-index: 20; */
  }

  [dir="rtl"] .slick-prev {
    right: -25px;
    left: auto;
    z-index: 20;
    /* background-color: red; */
    /* background-color: "#e38a17"; */
  }

  .slick-prev:before {
    content: "<";
    padding: 10px;
    background-color: #e38a17;
  }

  [dir="rtl"] .slick-prev:before {
    content: ">";
    padding: 10px;
    /* background-color: red; */
  }

  .slick-next {
    right: 40px;
  }

  [dir="rtl"] .slick-next {
    right: auto;
    left: -25px;
  }

  .slick-next:before {
    content: ">";
    padding: 10px;
    background-color: #e38a17;
  }

  [dir="rtl"] .slick-next:before {
    content: "<";
  }
  @media screen and (max-width: 768px) {
    .slick-next {
      right: 15px;
    }

    .slick-prev {
      left: 5px;
      /* background-color: red; */
      /* z-index: 20; */
    }
  }
  @media screen and (max-width: 360px) {
    .slick-next {
      right: 5px;
    }

    .slick-prev {
      left: -5px;
      /* background-color: red; */
      /* z-index: 20; */
    }
  }
  @media screen and (max-width: 320px) {
    .slick-next {
      right: 28px;
    }

    .slick-prev {
      left: 20px;
      /* background-color: red; */
      /* z-index: 20; */
    }
  }
`;
export default function Kundenstimmen() {
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    className: "",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,

          // initialSlide: 2
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 459,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 359,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  return (
    <div tw="px-2 py-10 bg-pribg sm:px-6 md:px-12">
      <span tw="flex items-center mb-20 w-full lg:mb-28">
        <div tw="hidden w-full md:block" className="">
          <hr tw="items-center self-center w-full h-1 bg-primary" />
        </div>

        <p
          className="flex-initial"
          tw="mx-4 w-full text-center text-4xl font-semibold md:whitespace-nowrap"
        >
          Kundenstimmen
        </p>
        <div tw="hidden w-full md:block" className="">
          <hr tw="items-center self-center w-full h-1 bg-primary" />
        </div>
      </span>
      <div tw="">
        <div className="">
          <StyledSlidder {...settings} tw="w-full">
            {/* 1 */}
            {/* <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6"> */}
            <li>
              <div tw="px-2 text-center space-y-4 md:px-2 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xs:w-36 w-48 xsm:h-32 xs:h-36 h-48 sm:w-44 sm:h-44 md:w-52 md:h-56 lg:w-68 lg:h-68 xl:w-80 xl:h-80"
                  src="../images/MuD_Rezensionen_2020_4.jpg"
                  alt=""
                />
              </div>
            </li>
            {/* </ul> */}
            {/* <ul tw="col-gap-4 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6"> */}
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xs:w-36 w-48 xsm:h-32 xs:h-36 h-48 sm:w-44 sm:h-44 md:w-52 md:h-56 lg:w-68 lg:h-68 xl:w-80 xl:h-80"
                  src="../images/MuD_Rezensionen_2020_5.jpg"
                  alt=""
                />
              </div>
            </li>
            {/* </ul> */}
            {/* <ul tw="col-gap-4 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6"> */}
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xs:w-36 w-48 xsm:h-32 xs:h-36 h-48 sm:w-44 sm:h-44 md:w-52 md:h-56 lg:w-68 lg:h-68 xl:w-80 xl:h-80"
                  src="../images/MuD_Rezensionen_2020_3.jpg"
                  alt=""
                />
              </div>
            </li>
            {/* </ul> */}
            <ul tw="col-gap-4 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
              <li>
                <div tw="px-2 text-center space-y-4 md:px-4">
                  <StaticImage
                    tw="mx-auto xsm:w-32 xs:w-36 w-48 xsm:h-32 xs:h-36 h-48 sm:w-44 sm:h-44 md:w-52 md:h-56 lg:w-68 lg:h-68 xl:w-80 xl:h-80"
                    src="../images/MuD_Rezensionen_2020_2.jpg"
                    alt=""
                  />
                </div>
              </li>
            </ul>
            <ul tw="col-gap-4 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
              <li>
                <div tw="px-2 text-center space-y-4 md:px-4">
                  <StaticImage
                    tw="mx-auto xsm:w-32 xs:w-36 w-48 xsm:h-32 xs:h-36 h-48 sm:w-44 sm:h-44 md:w-52 md:h-56 lg:w-68 lg:h-68 xl:w-80 xl:h-80"
                    src="../images/MuD_Rezensionen_2020_1.jpg"
                    alt=""
                  />
                </div>
              </li>
            </ul>
            <ul tw="col-gap-4 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
              <li>
                <div tw="px-2 text-center space-y-4 md:px-4">
                  <StaticImage
                    tw="mx-auto xsm:w-32 xs:w-36 w-48 xsm:h-32 xs:h-36 h-48 sm:w-44 sm:h-44 md:w-52 md:h-56 lg:w-68 lg:h-68 xl:w-80 xl:h-80"
                    src="../images/MuD_Rezensionen_2019_1.jpg"
                    alt=""
                  />
                </div>
              </li>
            </ul>
            <ul tw="col-gap-4 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
              <li>
                <div tw="px-2 text-center space-y-4 md:px-4">
                  <StaticImage
                    tw="mx-auto xsm:w-32 xs:w-36 w-48 xsm:h-32 xs:h-36 h-48 sm:w-44 sm:h-44 md:w-52 md:h-56 lg:w-68 lg:h-68 xl:w-80 xl:h-80"
                    src="../images/MuD_Rezensionen_2019_2.jpg"
                    alt=""
                  />
                </div>
              </li>
            </ul>
            <ul tw="col-gap-4 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
              <li>
                <div tw="px-2 text-center space-y-4 md:px-4">
                  <StaticImage
                    tw="mx-auto xsm:w-32 xs:w-36 w-48 xsm:h-32 xs:h-36 h-48 sm:w-44 sm:h-44 md:w-52 md:h-56 lg:w-68 lg:h-68 xl:w-80 xl:h-80"
                    src="../images/MuD_Rezensionen_2019_3.jpg"
                    alt=""
                  />
                </div>
              </li>
            </ul>
            <ul tw="col-gap-4 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
              <li>
                <div tw="px-2 text-center space-y-4 md:px-4">
                  <StaticImage
                    tw="mx-auto xsm:w-32 xs:w-36 w-48 xsm:h-32 xs:h-36 h-48 sm:w-44 sm:h-44 md:w-52 md:h-56 lg:w-68 lg:h-68 xl:w-80 xl:h-80"
                    src="../images/MuD_Rezensionen_2019_4.jpg"
                    alt=""
                  />
                </div>
              </li>
            </ul>
          </StyledSlidder>
        </div>
      </div>
      <div tw="flex justify-center my-16">
        <a
          href="https://www.google.de/search?ei=PhS9X7GtKIjXkwWP_r3IAg&q=md+kreativ+k%C3%BCchen&oq=md+kreativ+k%C3%BCchen&gs_lcp=CgZwc3ktYWIQAzICCAA6BAgAEEc6BQgAELEDOggIABCxAxCDAToECAAQQzoHCAAQsQMQQzoGCAAQFhAeOggIABAWEAoQHlDB1ENYjN9DYLDfQ2gAcAJ4AYABggKIAesNkgEFOC42LjKYAQCgAQGqAQdnd3Mtd2l6yAEIwAEB&sclient=psy-ab&ved=0ahUKEwixxKTxrZvtAhWI66QKHQ9_DykQ4dUDCAw&uact=5#lrd=0x4165556946c06c6f:0x35e85ac7c55f9fdf,1,,,"
          className=""
          tw="inline-block mx-auto px-4 py-2 text-center text-secondary bg-primary"
        >
          MEHR REZENSIONEN AUF GOOGLE
        </a>
      </div>
    </div>
  );
}
