/* EXAMPLE */

import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import "twin.macro";
import EmbedYoutube from "../components/EmbedYoutube";
// import ScrollToTop from "react-scroll-to-top";
import Besuchen from "../components/Besuchen";
import Projekte from "../components/Projekte";
import Kuchenstudio from "../components/Kuchenstudio";
import Inspirationen from "../components/Inspirationen";
import Partnermarken from "../components/Partnermarken";
import Kundenstimmen from "../components/Kundenstimmen";
// const Input = () => <input tw="border hover:border-black" />;

// markup
const IndexPage = () => {
  return (
    <Layout tw="overflow-y-hidden">
      <>
        <SEO />
        {/* <ScrollToTop
        aria-label="scroll to top"
        style={{ backgroundColor: "" }}
        className="scroll-to-top"
     
        smooth
        top={0}
      /> */}
        {/* <Input /> */}
        <main>
          <title>Home Page</title>
        </main>
        {/* Section 1 */}
        <div tw="px-2 py-36 bg-pribg sm:px-6 md:px-12">
          <span tw="flex items-center w-full">
            {/* <hr tw="flex-none items-center self-center w-full h-1 bg-primary" /> */}
            <div
              tw="hidden w-full md:block"
              className=""
              // style={{ borderColor: "#902b4b", border: "5px solid" }}
            >
              <hr tw="items-center self-center w-full h-1 bg-primary" />
            </div>

            <p
              className="flex-initial"
              tw="mx-4 w-full text-center text-4xl font-semibold tracking-wider md:whitespace-nowrap"
            >
              praktisch gedacht, pfiffig gemacht
            </p>
            {/* <hr tw="items-center self-center w-full h-1 bg-primary" /> */}
            <div
              tw="hidden w-full md:block"
              className=""
              // style={{ borderColor: "#902b4b", border: "5px solid" }}
            >
              <hr tw="items-center self-center w-full h-1 bg-primary" />
            </div>
          </span>
        </div>
        {/* Section 2 */}
        <div tw="flex flex-col justify-center py-12 bg-graybg">
          {/* <p tw="mb-4 px-12 text-center text-xl font-semibold" className="">
            Aus datenschutzrechlichen Gründen benötigt YouTube Ihre Einwilligung
            um geladen zu werden. Mehr Informationen finden Sie unter
            Datenschutzerklärung.
          </p>
          <button
            className=""
            tw="inline-block mx-auto px-4 py-2 text-center text-secondary bg-primary"
          >
            AKZEPTIEREN
          </button> */}
          <div tw="flex justify-center my-6 w-full">
            <EmbedYoutube videoId="c3ZKrMbWFYQ" tw="mx-auto" />
          </div>
        </div>
        {/* Section 3 */}
        <Kuchenstudio />
        {/* Section 4 */}
        <Inspirationen />
        {/* Section: 5 */}
        <Projekte />
        {/* Section 6 */}
        <Partnermarken />
        {/* Section 7 */}
        <Kundenstimmen />
        {/* Section 8 */}
        <Besuchen />
      </>
    </Layout>
  );
};

export default IndexPage;
