import React from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";
import { styled } from "twin.macro";

import "twin.macro";

const StyledSlidder = styled(Slider)`
  /* Arrows */

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    z-index: 20;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }

  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
    background-color: "#e38a17";
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 18px;
    font-weight: 900;
    line-height: 1;
    opacity: 0.9;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: 5px;
    /* background-color: red; */
    /* z-index: 20; */
  }

  [dir="rtl"] .slick-prev {
    right: -25px;
    left: auto;
    z-index: 20;
    /* background-color: red; */
    /* background-color: "#e38a17"; */
  }

  .slick-prev:before {
    content: "<";
    padding: 10px 15px 12px;
    background-color: #e38a17;
  }

  [dir="rtl"] .slick-prev:before {
    content: ">";
    padding: 10px;
    /* background-color: red; */
  }

  .slick-next {
    right: 25px;
  }

  [dir="rtl"] .slick-next {
    right: auto;
    left: -25px;
  }

  .slick-next:before {
    content: ">";
    padding: 10px 15px 12px;

    background-color: #e38a17;
  }

  [dir="rtl"] .slick-next:before {
    content: "<";
  }
  @media screen and (max-width: 768px) {
    .slick-next {
      /* right: 15px; */
    }

    .slick-prev {
      left: 5px;
      /* background-color: red; */
      /* z-index: 20; */
    }
  }
`;
export default function Kuchenstudio() {
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    className: "",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,

          // initialSlide: 2
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  return (
    <div tw="px-2 py-16 bg-pribg sm:px-6 md:px-12" id="inspiration">
      <span tw="flex items-center mb-20 w-full lg:mb-28">
        {/* <hr tw="flex-none items-center self-center w-full h-1 bg-primary" /> */}
        <div
          tw="hidden w-full md:block"
          className=""
          // style={{ borderColor: "#902b4b", border: "5px solid" }}
        >
          <hr tw="items-center self-center w-full h-1 bg-primary" />
        </div>

        <p
          className="flex-initial"
          tw="mx-4 w-full text-center text-4xl font-semibold md:whitespace-nowrap"
        >
          Inspirationen unserer Küchenhersteller
        </p>
        {/* <hr tw="items-center self-center w-full h-1 bg-primary" /> */}
        <div
          tw="hidden w-full md:block"
          className=""
          // style={{ borderColor: "#902b4b", border: "5px solid" }}
        >
          <hr tw="items-center self-center w-full h-1 bg-primary" />
        </div>
      </span>
      {/* <p tw="flex mb-12 w-full">
        <hr tw="items-center self-center w-full h-1 bg-primary" />
        <p tw="inline flex-grow w-full text-center text-4xl font-semibold">
          Inspirationen unserer Küchenhersteller
        </p>
        <hr tw="items-center self-center w-full h-1 bg-primary" />
      </p> */}
      <div tw="lg:flex lg:gap-10">
        <div tw="lg:w-2/3">
          <StyledSlidder {...settings} tw="w-full">
            {/* 1 */}
            {/* <ul tw="mx-auto grid grid-cols-2 col-gap-4 row-gap-8 sm:grid-cols-4 md:col-gap-6 lg:max-w-5xl lg:col-gap-8 lg:row-gap-12 xl:grid-cols-6 ">
              <li>
                <div tw="space-y-4 px-4"> */}
            <StaticImage
              tw="h-80 md:h-124"
              src="../images/121095173_2734213336848444_5282859857325643725_o.jpg"
              alt=""
            />
            <StaticImage
              tw="h-80 md:h-124"
              src="../images/91236648_2575466699389776_1292982479778480128_n.jpg"
              alt=""
            />
            <StaticImage
              tw="h-80 md:h-124"
              src="../images/90908235_2575480512721728_1806737242136248320_n.jpg"
              alt=""
            />
            <StaticImage
              tw="h-80 md:h-124"
              src="../images/82751029_2519032381699875_8398653335799857152_o.jpg"
              alt=""
            />
            <StaticImage
              tw="h-80 md:h-124"
              src="../images/82611114_2519032218366558_4160860464055582720_n.jpg"
              alt=""
            />
            <StaticImage
              tw="h-80 md:h-124"
              src="../images/82235607_2515624075374039_1417477024590594048_n.jpg"
              alt=""
            />
            <StaticImage
              tw="h-80 md:h-124"
              src="../images/72782334_2433211006948680_6100261557973811200_n.jpg"
              alt=""
            />
            <StaticImage
              tw="h-80 md:h-124"
              src="../images/50095620_2239898876279895_8732217160480325632_o.jpg"
              alt=""
            />
             <StaticImage
              tw="h-80 md:h-124"
              src="../images/74582975_2460036790932768_7487864490860478464_n.jpg"
              alt=""
            />
            {/* </div>
              </li>
            </ul> */}
          </StyledSlidder>
        </div>
        <div tw="mt-12 lg:mt-0 lg:w-1/3">
          <div tw="px-8 text-black text-xl font-medium leading-10 list-none">
            <div>
              <div>
                <p tw="my-4">
                In unseren Räumlichkeiten finden Sie attraktive Ausstellungsküchen der Marken 
                </p>
                <ul tw="my-4 list-disc">
                  <li>Bauformat</li>
                  <li>Schüller und</li> 
                  <li>Burger</li>
                </ul>
                <p tw="my-4">
                die wir mit Elektrogeräten der Marken Miele, Neff, Gorenje, Liebherr und Silverline ausgestattet haben. Franke und SystemCeram ergänzen die Küchen im Bereich Spülen und Armaturen. Zubehör bieten wir von Naber und Sagemüller & Rohrer an.
                </p>
                <p tw="my-4"></p>
                <p>Freuen Sie sich auf die Ideen unserer Hersteller.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
