import React from "react";
import "twin.macro";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import Address from "../components/Address";

export default function Besuchen() {
  const { site } = useStaticQuery(query);
  const { companyName, tel, fax, domain } = site.siteMetadata;

  return (
    <div tw="px-6 py-12 bg-pribg md:px-10" id="kontakt">
      <span tw="flex items-center mb-20 w-full md:mb-28">
        {/* <hr tw="flex-none items-center self-center w-full h-1 bg-primary" /> */}
        <div
          tw="hidden w-full sm:block"
          className=""
          // style={{ borderColor: "#902b4b", border: "5px solid" }}
        >
          <hr tw="items-center self-center w-full h-1 bg-primary" />
        </div>

        <div
          className="flex-initial"
          tw="mx-4 w-full text-center text-4xl font-semibold sm:whitespace-nowrap"
        >
          Besuchen Sie uns
        </div>
        {/* <hr tw="items-center self-center w-full h-1 bg-primary" /> */}
        <div
          tw="hidden w-full sm:block"
          className=""
          // style={{ borderColor: "#902b4b", border: "5px solid" }}
        >
          <hr tw="items-center self-center w-full h-1 bg-primary" />
        </div>
      </span>

      <div tw="grid lg:gap-10 lg:grid-cols-2">
        <div className="">
          <StaticImage
            src="../images/Team.jpg"
            alt="Team"
            tw="inset-0 w-full h-full object-cover"
          />
        </div>
        <div
          className="prose lg:prose-xl"
          tw="mt-16 py-2 text-black text-xl font-medium leading-10 md:px-8 lg:mt-0"
        >
          <p className="pt-0">{companyName}</p>
          <Address />
          <p>
            Tel.: <a href={`tel:${tel[0]}`}>{tel[0]}</a>
          </p>
          {(tel[1] ? true : null) && (
            <p>
              Tel.: <a href={`tel:${tel[1]}`}>{tel[1]}</a>
            </p>
          )}
          <p>Fax: {fax}</p>
          <br></br>
          <p>
            Email: <a href={`mailto:info@${domain}`}>{`info@${domain}`}</a>
            <br></br>
          </p>
          <p>Öffnungszeiten</p>
          <p>Mo geschlossen</p>
          <p>Di-Fr 10:00 – 18:00 Uhr</p>
          <p>Sa 10:00 – 13:00 Uhr</p>
        </div>
      </div>
    </div>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        companyName
        tel
        fax
        domain
      }
    }
  }
`;
