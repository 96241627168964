import React from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";
import "twin.macro";
import { styled } from "twin.macro";

const StyledSlidder = styled(Slider)`
  /* Arrows */

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    z-index: 20;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }

  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
    background-color: "#e38a17";
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 14px;
    font-weight: 900;
    line-height: 1;
    opacity: 0.9;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: 15px;
    /* background-color: red; */
    /* z-index: 20; */
  }

  [dir="rtl"] .slick-prev {
    right: -25px;
    left: auto;
    z-index: 20;
    /* background-color: red; */
    /* background-color: "#e38a17"; */
  }

  .slick-prev:before {
    content: "<";
    padding: 10px;
    background-color: #e38a17;
  }

  [dir="rtl"] .slick-prev:before {
    content: ">";
    padding: 10px;
    /* background-color: red; */
  }

  .slick-next {
    right: 25px;
  }

  [dir="rtl"] .slick-next {
    right: auto;
    left: -25px;
  }

  .slick-next:before {
    content: ">";
    padding: 10px;
    background-color: #e38a17;
  }

  [dir="rtl"] .slick-next:before {
    content: "<";
  }

  @media screen and (max-width: 768px) {
    .slick-next {
      right: 15px;
    }

    .slick-prev {
      left: 5px;
      /* background-color: red; */
      /* z-index: 20; */
    }
  }
  @media screen and (max-width: 360px) {
    .slick-next {
      right: 5px;
    }

    .slick-prev {
      left: -5px;
      /* background-color: red; */
      /* z-index: 20; */
    }
  }
  @media screen and (max-width: 320px) {
    .slick-next {
      right: 28px;
    }

    .slick-prev {
      left: 20px;
      /* background-color: red; */
      /* z-index: 20; */
    }
  }
`;
export default function Partnermarken() {
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    className: "",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          arrows: true,

          // initialSlide: 2
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 439,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 359,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  return (
    <div tw="px-2 py-16 bg-pribg sm:px-6 md:px-12" id="partnermarken">
      <span tw="flex items-center mb-20 w-full md:mb-28">
        {/* <hr tw="flex-none items-center self-center w-full h-1 bg-primary" /> */}
        <div
          tw="hidden w-full md:block"
          className=""
          // style={{ borderColor: "#902b4b", border: "5px solid" }}
        >
          <hr tw="items-center self-center w-full h-1 bg-primary" />
        </div>

        <p
          className="flex-initial"
          tw="mx-4 w-full text-center text-4xl font-semibold md:whitespace-nowrap"
        >
          Unsere starken Partnermarken
        </p>
        {/* <hr tw="items-center self-center w-full h-1 bg-primary" /> */}
        <div
          tw="hidden w-full md:block"
          className=""
          // style={{ borderColor: "#902b4b", border: "5px solid" }}
        >
          <hr tw="items-center self-center w-full h-1 bg-primary" />
        </div>
      </span>
      {/* <p tw="flex mb-12 w-full">
        <hr tw="items-center self-center w-full h-1 bg-primary" />
        <span tw="inline w-full text-center text-4xl font-semibold">
          Unsere starken Partnermarken
        </span>
        <hr tw="items-center self-center w-full h-1 bg-primary" />
      </p> */}
      <div className="">
        <StyledSlidder {...settings} tw="w-full">
          {/* 1 */}
          <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xxs:w-32 w-36 w-48 xsm:h-28 xxs:h-32 h-36 h-48 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-56 lg:h-56 xl:w-72 xl:h-72"
                  src="../images/Logo_burger_bauformat.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
          <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xxs:w-32 w-36 w-48 xsm:h-28 xxs:h-32 h-36 h-48 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-56 lg:h-56 xl:w-72 xl:h-72"
                  src="../images/Logo_Schueller.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
          <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xxs:w-32 w-36 w-48 xsm:h-28 xxs:h-32 h-36 h-48 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-56 lg:h-56 xl:w-72 xl:h-72"
                  src="../images/Logo_Neff.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
          <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xxs:w-32 w-36 w-48 xsm:h-28 xxs:h-32 h-36 h-48 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-56 lg:h-56 xl:w-72 xl:h-72"
                  src="../images/Logo_berbel.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
          <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xxs:w-32 w-36 w-48 xsm:h-28 xxs:h-32 h-36 h-48 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-56 lg:h-56 xl:w-72 xl:h-72"
                  src="../images/Logo_Franke.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
          <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xxs:w-32 w-36 w-48 xsm:h-28 xxs:h-32 h-36 h-48 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-56 lg:h-56 xl:w-72 xl:h-72"
                  src="../images/Logo_Miele.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
          <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xxs:w-32 w-36 w-48 xsm:h-28 xxs:h-32 h-36 h-48 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-56 lg:h-56 xl:w-72 xl:h-72"
                  src="../images/Logo_sedia.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
          <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xxs:w-32 w-36 w-48 xsm:h-28 xxs:h-32 h-36 h-48 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-56 lg:h-56 xl:w-72 xl:h-72"
                  src="../images/Logo_Silverline.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
          <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xxs:w-32 w-36 w-48 xsm:h-28 xxs:h-32 h-36 h-48 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-56 lg:h-56 xl:w-72 xl:h-72"
                  src="../images/Logo_Blanco.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
          <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xxs:w-32 w-36 w-48 xsm:h-28 xxs:h-32 h-36 h-48 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-56 lg:h-56 xl:w-72 xl:h-72"
                  src="../images/Logo_Lechner.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
          <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xxs:w-32 w-36 w-48 xsm:h-28 xxs:h-32 h-36 h-48 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-56 lg:h-56 xl:w-72 xl:h-72"
                  src="../images/Logo_gorenje.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
          <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xxs:w-32 w-36 w-48 xsm:h-28 xxs:h-32 h-36 h-48 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-56 lg:h-56 xl:w-72 xl:h-72"
                  src="../images/Logo_Constructa.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
          <ul tw="col-gap-2 row-gap-8 md:col-gap-6 lg:col-gap-8 lg:row-gap-12 grid grid-cols-2 justify-center mx-auto sm:grid-cols-4 lg:max-w-5xl xl:grid-cols-6">
            <li>
              <div tw="px-2 text-center space-y-4 md:px-4">
                <StaticImage
                  tw="mx-auto xsm:w-32 xxs:w-32 w-36 w-48 xsm:h-28 xxs:h-32 h-36 h-48 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-56 lg:h-56 xl:w-72 xl:h-72"
                  src="../images/Logo_Naber.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </StyledSlidder>
      </div>
    </div>
  );
}
