import React from "react";
import PropTypes from "prop-types";
import { Link, useStaticQuery, graphql } from "gatsby";
import ReactPlayer from "react-player/youtube";
import "twin.macro";

function PlayIcon() {
  return (
    <div tw="mx-10 2xl:mx-40 p-4 text-justify 2xl:text-2xl text-xs bg-white bg-opacity-75 rounded-xl shadow-md sm:mx-14 sm:text-sm md:mx-20 md:text-base lg:mx-24 lg:text-lg xl:mx-32 xl:text-xl">
      <p>
        Mit dem Klick auf diese Fläche stimme ich der Datenfreigabe an Google
        Ireland Limited zu.
      </p>
      <p>
        <Link to="/datenschutz" tw="hover:text-red-300">
          → Datenschutz
        </Link>
      </p>
    </div>
  );
}

// Parameters inspired by https://www.npmjs.com/package/react-youtube
export default function EmbedYoutube({ videoId }) {
  const { allFile } = useStaticQuery(query);
  let videoIdThumbnail;

  for (let node of allFile.nodes) {
    const nodeNameID = node.name.substring(8);
    if (nodeNameID === videoId) {
      videoIdThumbnail =
        node.childImageSharp.gatsbyImageData.images.fallback.src;
    }
  }

  return (
    <div tw="aspect-w-16 aspect-h-9 md:aspect-h-7 w-3/4 overflow-auto">
      <ReactPlayer
        // light="/youtube_logo_2017_padding.svg"
        light={`${videoIdThumbnail}`}
        playing
        playIcon={<PlayIcon />}
        width="100%"
        height="100%"
        url={"https://www.youtube-nocookie.com/embed/" + videoId}
        config={{
          youtube: {
            playerVars: { controls: 1 },
          },
        }}
      />
    </div>
  );
}

EmbedYoutube.propTypes = {
  videoId: PropTypes.string.isRequired,
};

const query = graphql`
  query YoutubeQuery {
    allFile(filter: { name: { glob: "youtube-*" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(formats: [JPG, WEBP])
        }
      }
    }
  }
`;
